.button {
  background-color: #edf2f7;
  border-radius: 0.375rem;
  padding: 8px 16px;
  color: black;
  font-size: 16px;
  /* line-height: 2; */
}

.subText {
  color: white;
  font-size: 16px;
  line-height: 100%;
  margin: 10px 0px;
  opacity: 0.8;
  /* padding-left: 0px !important; */
}
