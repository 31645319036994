.mainContainer {
  display: flex;
  justify-content: center;
  /* height: 900px; */
  padding-top: 293px;
  margin-top: -100px;
  padding-bottom: 250px;

  overflow: hidden;
}

.subContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1440px;
}

.GradientL {
  position: absolute;
  left: -5%;
  bottom: 35%;
  width: 314.5px;
  height: 187px;
  /* border-radius: 30%; */
  background: #caff00;
  filter: blur(271.9999694824219px);
}

.GradientR {
  position: absolute;
  right: -15%;
  top: 25%;
  /* border-radius: 314.5px; */
  background: #c2ea2b;
  filter: blur(271.9999694824219px);
  width: 314.5px;
  height: 187px;
}

.bgM {
  display: none;
}

.bgD {
  width: 100%;
}

.textContainer {
  display: none;
}

@media (max-width: 1480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    padding-top: 223px;
    margin-top: -100px;
    padding-bottom: 150px;

    overflow: hidden;
  }
  .GradientL {
    position: absolute;
    left: 0%;
    bottom: 40%;
    width: 20.38vw;
    height: 127px;
    /* border-radius: 30%; */
    background: #caff00;
    filter: blur(271.9999694824219px);
  }

  .GradientR {
    position: absolute;
    right: -5%;
    top: 25%;
    /* border-radius: 314.5px; */
    background: #c2ea2b;
    filter: blur(271.9999694824219px);
    width: 20.38vw;
    height: 127px;
  }
}

@media (max-width: 1024px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    padding-top: 223px;
    margin-top: -100px;
    padding-bottom: 150px;

    overflow: hidden;
  }
  .GradientL {
    position: absolute;
    left: 0%;
    bottom: 40%;
    width: 20.38vw;
    height: 127px;
    /* border-radius: 30%; */
    background: #caff00;
    filter: blur(271.9999694824219px);
  }

  .GradientR {
    position: absolute;
    right: -5%;
    top: 25%;
    /* border-radius: 314.5px; */
    background: #c2ea2b;
    filter: blur(271.9999694824219px);
    width: 20.38vw;
    height: 127px;
  }
}
@media (max-width: 780px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    padding-top: 135px;
    margin-top: -100px;
    padding-bottom: 120px;

    overflow: hidden;
  }

  .subContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* width: 90%; */
  }
  .GradientL {
    /* display: none; */
    position: absolute;
    left: -300px;
    bottom: 35%;
    width: 193.859px;
    height: 115.268px;
    /* border-radius: 30%; */
    background: #caff00;
    filter: blur(167.66195678710938px);
  }

  .GradientR {
    /* display: none; */
    position: absolute;
    right: -300px;
    top: 25%;
    /* border-radius: 314.5px; */
    background: #c2ea2b;
    filter: blur(167.66195678710938px);
    width: 193.859px;
    height: 115.268px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 398px;
    margin-top: 100px;
    margin-bottom: -180px;
  }

  .logoContainer {
    width: 50.7px;
    height: 60.199px;
    margin-bottom: 17px;
  }



/* 
.logoContainer > img {
  width: 100%;
  height: 100%;
  animation: zoomInOut 4s infinite;
}

.logoContainer {
  position: absolute;
  width: 40.7px;
  height: 50.199px;
  top: 10px;
  left: 49%;
}

@keyframes zoomInOut {
  0%, 100% {
    transform: scale(0.8); 
  }
  50% {
    transform: scale(1); 
  }
}


.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 398px;
  margin-top: 100px;
  margin-bottom: -180px;
} */


  .logoContainer > img {
    width: 100%;
    height: 100%;
  }
  .mainHeading {
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 52px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 52px */
    text-transform: uppercase;
    margin-bottom: 28px;
  }

  .subHeading {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 177.778% */
    text-transform: capitalize;
  }

  .bgD {
    display: none;
  }

  .bgM {
    display: flex;
    width: 100%;
    max-width: 480px;
  }

  .bgM > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    padding-top: 31.395vw;
    margin-top: -23.256vw;
    padding-bottom: 27.907vw;

    overflow: hidden;
  }

  .subContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* width: 90%; */
  }

  .mainHeading {
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 12.093vw;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 52px */
    text-transform: uppercase;
    margin-bottom: 6.512vw;
  }

  .subHeading {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 4.186vw;
    font-style: normal;
    font-weight: 400;
    line-height: 7.442vw; /* 177.778% */
    text-transform: capitalize;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 92.558vw;
    margin-top: 23.256vw;
    margin-bottom: -41.86vw;
  }

  .logoContainer {
    width: 11.791vw;
    height: 14vw;
    margin-bottom: 3.953vw;
  }
  .GradientL {
    /* display: none; */
    position: absolute;
    left: -69.767vw;
    bottom: 35%;
    width: 45.083vw;
    height: 26.807vw;
    /* border-radius: 30%; */
    background: #caff00;
    filter: blur(167.66195678710938px);
  }

  .GradientR {
    /* display: none; */
    position: absolute;
    right: -69.767vw;
    top: 25%;
    /* border-radius: 314.5px; */
    background: #c2ea2b;
    filter: blur(167.66195678710938px);
    width: 45.083vw;
    height: 26.807vw;
  }
  .bgD {
    display: none;
  }

  .bgM {
    display: flex;
    width: 100%;
  }

  .bgM > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
