.LogoContainer {
  margin-top: 50px;
  margin-bottom: 70px;
  animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-name: zoomInOut;
    animation-timing-function: cubic-bezier(1, 0.01, 0, 1.04);
    transition: transform 2s cubic-bezier(0.96, 0.02, 0.12, 1);
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.8); /* Start and end at normal size */
  }
  50% {
    transform: scale(1); /* Midway through the animation, zoom in */
  }
}

.LogoContainer:hover {
  animation-play-state: paused; /* Pause animation on hover */
}
.legalContainer > * {
  color: white;
  font-style: bold;
}
.headerLogo {
  margin-top: 45px;
}
.headerLogo > img {
  width: 126px;
  height: 100%;
}

.linkItem {
  color: #fff;
  font-family: TelegraphReg;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
}

.contractAddress {
  color: #fff;
  font-family: TelegraphReg;
  font-size: 14px;
  font-style: bold;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;
  text-align: center;
}

.copyright {
  color: #fff;
  opacity: 0.5;
  font-family: TelegraphReg;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
