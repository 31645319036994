.mainContainer {
  display: flex;
  justify-content: center;
  /* height: 900px; */
  padding-bottom: 40px;
  margin-top: 70px;
  background: url("../../public/images/whyBg.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 1500px;
}

.subContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  max-width: 1417px;
}

.headingContainer {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
}

.mainHeading {
  padding-top: 200px;
  color: #141625;
  font-family: TelegraphReg;
  font-size: 72px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.subHeading {
  color: #141625;
  font-family: TelegraphReg;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 158.333% */
  text-transform: capitalize;
}

.cardContainer {
  margin-top: 44px;
  display: flex;
  width: 100%;
  max-width: 1417px;
  column-gap: 20px;
  justify-content: space-between;
}

.sliderCont {
  display: none;
}

.Mcard {
  display: none;
}
.card {
  position: relative;
  /* width: 89%; */
  width: 421px;
}

.cardImage {
  position: relative;
  width: 100%;
background: linear-gradient(180deg, rgba(0, 0, 0, 0.06) 0%, #000 100%);  /* top: 0px; */
  border-radius: 20px;

}


.cardImage > img {
  width: 100%;
  height: 100%;
  /* border-radius: 20px; */

}
.cardTextContainer {
  position: absolute;
  z-index: 2;
  bottom: 24px;
  left: 24px;
}
.cardTitle {
  color: #fff;
  font-family: TelegraphReg;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 200% */
  margin-bottom: 16px;
  text-transform: uppercase;
}

.cardDesc {
  padding-right: 39px;
  color: #fff;
  font-family: TelegraphReg;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
}

@media (max-width: 1480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    padding-bottom: 70px;
    margin-top: 70px;
    background: url("../../public/images/whyBg.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 1500px 53.041vw;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    max-width: 1417px;
  }

  .headingContainer {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
  }

  .mainHeading {
    padding-top: 200px;
    color: #141625;
    font-family: TelegraphReg;
    font-size: 72px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }

  .subHeading {
    color: #141625;
    font-family: TelegraphReg;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px; /* 158.333% */
    text-transform: capitalize;
  }

  .cardContainer {
    margin-top: 44px;
    display: flex;
    width: 100%;
    max-width: 1417px;
    column-gap: 20px;
    justify-content: space-between;
  }

  .sliderCont {
    display: none;
  }

  .Mcard {
    display: none;
  }
  .card {
    position: relative;
    /* width: 89%; */
    width: 421px;
  }

  .cardImage {
    position: relative;
    width: 100%;
    /* top: 0px; */
  }
  .cardImage > img {
    width: 100%;
    height: 100%;
  }
  .cardTextContainer {
    position: absolute;
    z-index: 2;
    bottom: 24px;
    left: 24px;
  }
  .cardTitle {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 200% */
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .cardDesc {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 187.5% */
  }
}

@media (max-width: 1280px) {
  .cardTitle {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 200% */
    margin-bottom: 16px;
    text-transform: uppercase;
  }

  .cardDesc {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 187.5% */
  }
}

@media (max-width: 1024px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    /* height: 100%; */
    padding-bottom: 0px;
    margin-top: 53px;
    background: url("../../public/images/whyBGM.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 580px;
  }

  .sliderCont {
    display: unset;
    width: 100%;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
    max-width: 1417px;
  }

  .headingContainer {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
  }

  .mainHeading {
    padding-top: 160px;
    color: #141625;
    font-family: TelegraphReg;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 28px;
  }

  .subHeading {
    text-align: center;
    color: #141625;
    font-family: TelegraphReg;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 158.333% */
    text-transform: capitalize;
  }

  .cardContainer {
    /* display: none; */
    margin-top: 44px;
    display: flex;
    width: 100%;
    max-width: 1417px;
    column-gap: 20px;
    justify-content: center;
  }
  .card {
    display: none;

    position: relative;
    /* width: 89%; */
    width: 421px;
  }

  .Mcard {
    display: flex;

    position: relative;
    /* width: 89%; */
    width: 100%;
    max-width: 421px;
  }

  .McardImage {
    position: relative;
    width: 100%;
    /* top: 0px; */
  }
  .McardImage > img {
    width: 100%;
    height: 100%;
  }
  .McardTextContainer {
    position: absolute;
    z-index: 2;
    bottom: 24px;
    left: 24px;
  }
  .McardTitle {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 200% */
    text-transform: uppercase;
    margin-bottom: 14px;
  }

  .McardDesc {
    padding-right: 20px;
    color: #fff;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 187.5% */
  }
}

@media (max-width: 780px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    /* height: 100%; */
    padding-bottom: 0px;
    margin-top: 53px;
    background: url("../../public/images/whyBGM.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 580px;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
    max-width: 1417px;
  }

  .headingContainer {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
  }

  .mainHeading {
    padding-top: 160px;
    color: #141625;
    font-family: TelegraphReg;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 28px;
  }

  .subHeading {
    text-align: center;
    color: #141625;
    font-family: TelegraphReg;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 158.333% */
    text-transform: capitalize;
  }

  .cardContainer {
    /* display: none; */
    margin-top: 44px;
    display: flex;
    width: 100%;
    max-width: 1417px;
    column-gap: 20px;
    justify-content: center;
  }
  .card {
    display: none;

    position: relative;
    /* width: 89%; */
    width: 421px;
  }

  .Mcard {
    display: flex;

    position: relative;
    /* width: 89%; */
    width: 100%;
    max-width: 421px;
  }

  .McardImage {
    position: relative;
    width: 100%;
    /* top: 0px; */
  }
  .McardImage > img {
    width: 100%;
    height: 100%;
  }
  .McardTextContainer {
    position: absolute;
    z-index: 2;
    bottom: 24px;
    left: 24px;
  }
  .McardTitle {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 200% */
    text-transform: uppercase;
    margin-bottom: 14px;
  }

  .McardDesc {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 187.5% */
  }
}

@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    /* height: 100%; */
    padding-bottom: 0px;
    margin-top: 12.326vw;
    background: url("../../public/images/whyBGM.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 580px;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 93%;
    max-width: 1417px;
  }

  .headingContainer {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start; */
  }

  .mainHeading {
    padding-top: 37.209vw;
    color: #141625;
    font-family: TelegraphReg;
    font-size: 12.093vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 6.512vw;
  }

  .subHeading {
    text-align: center;
    color: #141625;
    font-family: TelegraphReg;
    font-size: 4.186vw;
    font-style: normal;
    font-weight: 400;
    line-height: 7.442vw; /* 158.333% */
    text-transform: capitalize;
  }

  .cardContainer {
    /* display: none; */
    margin-top: 10.233vw;
    display: flex;
    width: 100%;
    max-width: 1417px;
    column-gap: 4.651vw;
    justify-content: center;
  }
  .card {
    display: none;

    position: relative;
    /* width: 89%; */
    width: 97.907vw;
  }

  .Mcard {
    display: flex;

    position: relative;
    /* width: 89%; */
    width: 100%;
    max-width: 97.907vw;
  }

  .McardImage {
    position: relative;
    width: 100%;
    /* top: 0px; */
  }
  .McardImage > img {
    width: 100%;
    height: 100%;
  }
  .McardTextContainer {
    position: absolute;
    z-index: 2;
    bottom: 5.581vw;
    left: 5.581vw;
  }
  .McardTitle {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 6.512vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 200% */
    text-transform: uppercase;
    margin-bottom: 3.256vw;
  }

  .McardDesc {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 187.5% */
  }
}

@media (min-width: 1920px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    margin-top: 70px;
    background: url("../../public/images/whyBg.svg");
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 79% 780px;
  }
}
