.mainContainer {
  display: flex;
  justify-content: center;
  /* height: 900px; */
}

.subContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.heading {
  color: #fff;

  text-align: center;
  font-family: TelegraphLight;
  font-size: 72px;
  font-style: normal;
  font-weight: 200;
  line-height: normal;
  text-transform: uppercase;
}

.subheading {
  color: #fff;
  text-align: center;
  font-family: TelegraphReg;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 158.333% */
  text-transform: capitalize;
}

.cardContainer {
  margin-top: 32px;
  display: flex;
  justify-content: center;
  column-gap: 53px;
}

.card1 {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  width: 602px;
  height: 599px;
  background: linear-gradient(180deg, #5937da 0%, #5c26db 100%);
  border-radius: 40px;
  text-decoration: none;
}

.cardTextContainer {
  padding: 0px 37px 32px 32px;
}

.cardTitle1 {
  color: #fff;
  font-family: TelegraphReg;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 133.333% */
  text-transform: uppercase;
}

.cardDesc1 {
  margin-top: 12px;
  width: 90%;
  color: #fff;
  font-family: TelegraphReg;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
}

.card2 {
  position: relative;
  width: 602px;
  height: 599px;
  /* width: 40%; */
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #c2ea2b 0%, #c2ea2b 90.15%);
  border-radius: 40px;
  text-decoration: none;
}

.cardTitle2 {
  color: #141625;
  font-family: TelegraphReg;
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  line-height: 64px; /* 133.333% */
  text-transform: uppercase;
}

.cardDesc2 {
  margin-top: 12px;
  color: #141625;
  font-family: TelegraphReg;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
}
.arrow {
  position: absolute;
  right: 32px;
  top: 32px;
  transition: all 0.4s ease; /* Add transition for smooth animation */
}

.card1:hover .arrow {
  right: 22px; /* Move rightward */
  top: 22px; /* Move downward */
}

.card2:hover .arrow {
  right: 22px; /* Move rightward */
  top: 22px; /* Move downward */
}

.cardImage {
  width: 404px;
}

@media (max-width: 1480px) {
  .mainContainer {
    display: flex;

    justify-content: center;
    /* height: 900px; */
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
  }
  .heading {
    color: #fff;

    text-align: center;
    font-family: TelegraphLight;
    font-size: 72px;
    font-style: normal;
    font-weight: 200;
    line-height: normal;
    text-transform: uppercase;
  }

  .subheading {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px; /* 158.333% */
    text-transform: capitalize;
  }

  .cardContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: none;
    row-gap: 30px;
  }

  .card1 {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 40%; */

    width: 502px;
    height: 499.5px;
    background: linear-gradient(180deg, #5937da 0%, #5c26db 100%);
    border-radius: 25px;
  }

  .cardTextContainer {
    padding: 13px 21px 24px 21px;
  }

  .cardTitle1 {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 30px */
    text-transform: uppercase;
  }

  .cardDesc1 {
    margin-top: 12px;
    width: 100%;
    color: #fff;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .card2 {
    position: relative;
    width: 502px;
    height: 499.5px;
    /* width: 40%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #c2ea2b 0%, #c2ea2b 90.15%);
    border-radius: 25px;
  }

  .cardTitle2 {
    color: #141625;
    font-family: TelegraphReg;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 30px */
    text-transform: uppercase;
  }

  .cardDesc2 {
    margin-top: 12px;
    color: #141625;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  .arrow {
    position: absolute;
    right: 32px;
    top: 32px;
  }

  .cardImage {
    width: 330px;
  }
}

@media (max-width: 1180px) {
  .mainContainer {
    display: flex;

    justify-content: center;
    /* height: 900px; */
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
  }

  .heading {
    color: #fff;

    text-align: center;
    font-family: TelegraphLight;
    font-size: 52px;

    font-weight: 200;
    line-height: 100%; /* 52px */
  }

  .subheading {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    text-transform: capitalize;
    margin-top: 28px;
  }

  .cardContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 30px;
    row-gap: 30px;
  }

  .card1 {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 40%; */

    width: 398px;
    height: 396px;
    background: linear-gradient(180deg, #5937da 0%, #5c26db 100%);
    border-radius: 25px;
  }

  .cardTextContainer {
    padding: 13px 21px 24px 21px;
  }

  .cardTitle1 {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 30px */
    text-transform: uppercase;
  }

  .cardDesc1 {
    margin-top: 12px;
    width: 100%;
    color: #fff;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .card2 {
    position: relative;
    width: 398px;
    height: 396px;
    /* width: 40%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #c2ea2b 0%, #c2ea2b 90.15%);
    border-radius: 25px;
  }

  .cardTitle2 {
    color: #141625;
    font-family: TelegraphReg;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 30px */
    text-transform: uppercase;
  }

  .cardDesc2 {
    margin-top: 12px;
    color: #141625;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  .arrow {
    position: absolute;
    right: 32px;
    top: 32px;
  }

  .cardImage {
    width: 242px;
  }
}

@media (max-width: 900px) {
  .mainContainer {
    display: flex;

    justify-content: center;
    /* height: 900px; */
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
  }

  .heading {
    color: #fff;

    text-align: center;
    font-family: TelegraphLight;
    font-size: 52px;
    max-width: 450px;

    font-weight: 200;
    line-height: 100%; /* 52px */
  }

  .subheading {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    text-transform: capitalize;
    margin-top: 28px;
  }

  .cardContainer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    column-gap: none;
    row-gap: 30px;
  }

  .card1 {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 40%; */

    width: 398px;
    height: 396px;
    background: linear-gradient(180deg, #5937da 0%, #5c26db 100%);
    border-radius: 25px;
  }

  .cardTextContainer {
    padding: 13px 21px 24px 21px;
  }

  .cardTitle1 {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 30px */
    text-transform: uppercase;
  }

  .cardDesc1 {
    margin-top: 12px;
    width: 100%;
    color: #fff;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .card2 {
    position: relative;
    width: 398px;
    height: 396px;
    /* width: 40%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #c2ea2b 0%, #c2ea2b 90.15%);
    border-radius: 25px;
  }

  .cardTitle2 {
    color: #141625;
    font-family: TelegraphReg;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 30px */
    text-transform: uppercase;
  }

  .cardDesc2 {
    margin-top: 12px;
    color: #141625;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  .arrow {
    position: absolute;
    right: 32px;
    top: 32px;
  }

  .cardImage {
    width: 242px;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    display: flex;

    justify-content: center;
    /* height: 900px; */
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
  }

  .heading {
    color: #fff;

    text-align: center;
    font-family: TelegraphLight;
    font-size: 12.093vw;

    font-weight: 200;
    line-height: 100%; /* 52px */
  }

  .subheading {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 4.186vw;
    font-style: normal;
    font-weight: 400;
    line-height: 7.442vw;
    text-transform: capitalize;
    margin-top: 6.512vw;
  }

  .cardContainer {
    margin-top: 11.628vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    column-gap: none;
    row-gap: 6.977vw;
  }

  .card1 {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;

    /* width: 40%; */

    width: 92.558vw;
    height: 92.093vw;
    background: linear-gradient(180deg, #5937da 0%, #5c26db 100%);
    border-radius: 25px;
  }

  .cardTextContainer {
    padding: 0px 4.884vw 5.581vw 4.884vw;
  }

  .cardTitle1 {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 6.977vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 30px */
    text-transform: uppercase;
  }

  .cardDesc1 {
    margin-top: 2.791vw;
    width: 100%;
    color: #fff;
    font-family: TelegraphReg;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }

  .card2 {
    position: relative;
    width: 92.558vw;
    height: 92.093vw;
    /* width: 40%; */
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180deg, #c2ea2b 0%, #c2ea2b 90.15%);
    border-radius: 25px;
  }

  .cardTitle2 {
    color: #141625;
    font-family: TelegraphReg;
    font-size: 6.977vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 30px */
    text-transform: uppercase;
  }

  .cardDesc2 {
    margin-top: 2.791vw;
    color: #141625;
    font-family: TelegraphReg;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
  }
  .arrow {
    position: absolute;
    right: 21px;
    top: 21px;

    width: 15px;
    height: 15px;
  }

  .cardImage {
    width: 56.279vw;
  }
}
