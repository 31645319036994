.mainContainer {
  display: flex;
  justify-content: center;
  position: relative;
  /* overflow: hidden; */
  /* background: url("../../public/images/heroBg.svg"); */

  /* background: url("../../public/images/heroBg.svg");
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-position: top center;
  background-repeat: no-repeat; */
  /* background-size:cover; */

  /* height: 900px; */
}

.subContainer {
  /* display: flex;
  flex-direction: column;
  align-items: center; */
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  max-width: 1150px;
  margin-top: 214px;
}

.textContainer {
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin-top: 255px;
  /* max-width: 1150px; */
}

@keyframes lightAnimation {
  0% {
    width: 0px;
  }

  40% {
    width: 100px;
  }
  80% {
    width: 00px;
  }
  100% {
    width: 100px;
  }
}

.animationContainer {
  display: unset;

  top: 53px;
  left: -188px;

  /* :180px ; */
  width: 50px;
  height: 60px;
  position: absolute;
  opacity: 0.7 !important;
}
.light {
  filter: blur(40px);
  width: 100px;
  height: 50px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  margin: -285px 0 0 -70px;
  border: 300px solid transparent;
  border-right: none;
  /* border-left: 200px solid rgb(180, 180, 180); */
  opacity: 0.3 !important;
  position: absolute;
  animation: lightAnimation 2s linear forwards;

  transform: rotate(185deg);
}

.lightBottom {
  filter: blur(40px);
  width: 100px;
  height: 50px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  margin: -285px 0 0 -70px;
  border: 300px solid transparent;
  border-right: none;

  opacity: 0.3;
  position: absolute;
  animation: lightAnimation2 2s linear forwards;

  transform: rotate(185deg);
}

@keyframes lightAnimation {
  0% {
    width: 0px;
    top: 0;
    opacity: 0;
    border-left: 0px;
  }
  10% {
    width: 5px;
  }
  20% {
    width: 10px;
  }
  30% {
    width: 20px;
  }
  40% {
    width: 25px;
  }
  50% {
    width: 30px;
  }
  60% {
    width: 35px;
  }
  70% {
    width: 40px;
    border-left: 0px;
  }
  100% {
    opacity: 0.3;
    border-left: 1180px solid rgb(180, 180, 180);
  }
}

@keyframes lightAnimation2 {
  0% {
    width: 0px;
    top: 0;
    opacity: 0;
    border-left: 0px;
  }
  10% {
    width: 5px;
  }
  20% {
    width: 10px;
  }
  30% {
    width: 20px;
  }
  40% {
    width: 25px;
  }
  50% {
    width: 30px;
  }
  60% {
    width: 35px;
  }
  70% {
    width: 40px;
    border-left: 0px;
  }
  100% {
    opacity: 0.3;
    border-left: 62vw solid rgb(180, 180, 180);
  }
}

@keyframes scanner-loop {
  0% {
    top: 0;
    width: 20px;
  }

  30% {
    top: 0;
    width: 20px;
  }
  100% {
    top: 0;
    width: 60px;
  }
}

.animation {
  /* transform: skewY(20deg); */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -8px;
  overflow: hidden;
  animation: scanner-loop 2s linear forwards;

  clip-path: polygon(
    0% 0%,
    0% 100%,
    /* calc(0% + 8px) 100%,
    calc(0% + 8px) calc(0% + 8px),
    calc(100% - 8px) calc(0% + 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(0% + 8px) calc(100% - 8px), */
      0% 100%,
    100% 100%,
    100% 0%
  );

  clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 87%);
  clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 86%);
}

.animation:before {
  content: "";
  display: block;
  background: linear-gradient(
    to right,
    hsl(75, 85%, 40%),
    hsl(75, 85%, 70%),
    #c2ea2b,
    hsl(75, 85%, 40%),
    hsl(75, 85%, 70%),
    #c2ea2b,
    hsl(75, 85%, 40%)
  );
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 400%;
  animation: dynamic-border-animation 2s linear infinite;
  transform: translateX(-50%);
}

@keyframes dynamic-border-animation {
  to {
    transform: translateX(0%);
  }
}

.centerText {
  position: relative;
  color: #fff;
  text-align: center;
  font-family: TelegraphLight;
  font-size: 133.714px;

  font-style: normal;
  font-weight: 200;
  line-height: 100%; /* 0px */
  text-transform: uppercase;
}

.buttonContainer {
  display: flex;
  width: 85%;
  justify-content: space-between;
  column-gap: 30px;
  margin-top: 15px;
  margin-bottom: 235px;
}

.button1 {
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 50px; */
  color: #050a02;
  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  /* font-weight: 800; */
  line-height: normal;
  letter-spacing: 0.64px;
  width: 447px;
  height: 62px;
  border-radius: 76px;
  background-color: #c2ea2b;
  text-decoration: none;
  transition: all 0.3s ease; /* Added transition effect */
}

.button1:hover {
  transform: translateY(-5px);
  filter: brightness(90%);
  transition: all 0.4s ease-in-out;
}

.button2 {
  /* width: 100%; */
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 50px; */
  color: #050a02;

  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  /* font-weight: 800; */
  line-height: normal;
  letter-spacing: 0.64px;
  width: 447px;
  height: 62px;
  border-radius: 76px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.button2:hover {
  transform: translateY(-5px);
  filter: brightness(80%);
  transition: all 0.4s ease-in-out;
}

.LogoContainer {
  /* margin-bottom: 33px;
  transition: transform 2s ease-in-out;
  animation-name: zoomInOut;
  animation-duration: 5s; 
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: zoomInOut;
  animation-timing-function: cubic-bezier(1, 0.01, 0, 1.04);
  margin-bottom: 33px;
  transition: transform 2s cubic-bezier(0.96, 0.02, 0.12, 1);
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.8); /* Start and end at normal size */
  }
  50% {
    transform: scale(1); /* Midway through the animation, zoom in */
  }
}

.LogoContainer:hover {
  animation-play-state: paused; /* Pause animation on hover */
}

.LogoContainer > img {
}

.linkContainer {
  display: flex;
  column-gap: 32px;
  margin-bottom: 36px;
}

.linkItem {
  color: #fff;
  font-family: TelegraphReg;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
  text-decoration: none;
}

.contractAddress {
  margin-bottom: 33px;
  color: #fff;
  font-family: TelegraphReg;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  word-break: break-all;
  text-align: center;
}

.copyright {
  margin-bottom: 88px;
  color: #fff;
  opacity: 0.5;
  font-family: TelegraphReg;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media (max-width: 1600px) {
  .animationContainer {
    top: 68px;
    left: -140px;
    /* -webkit-mask-position-x: fixed; */
    /* :180px ; */
    width: 20px;
    height: 55px;
    position: absolute;
    opacity: 0.7 !important;
  }
  .animation {
    /* transform: skewY(20deg); */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -8px;
    overflow: hidden;
    animation: scanner-loop 2s linear forwards;

    clip-path: polygon(
      0% 0%,
      0% 100%,
      /* calc(0% + 8px) 100%,
    calc(0% + 8px) calc(0% + 8px),
    calc(100% - 8px) calc(0% + 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(0% + 8px) calc(100% - 8px), */
        0% 100%,
      100% 100%,
      100% 0%
    );

    clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 87%);
    clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 86%);
    clip-path: polygon(19% 11%, 100% 0, 100% 100%, 19% 86%);
  }
}

@media (max-width: 1500px) {
 .centerText {
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 113.714px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 0px */
    text-transform: uppercase;
    max-width: 900px;
  }

  .buttonContainer {
    display: flex;
    width: 85%;
    justify-content: center;
    column-gap: 30px;
    margin-top: 15px;
    margin-bottom: 235px;
  }

  .button1 {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 390px;
    height: 62px;
    border-radius: 76px;
    background-color: #c2ea2b;
  }

  .button2 {
    /* width: 100%; */
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 390px;
    height: 62px;
    border-radius: 76px;
    /* background-color: #c2ea2b; */
  }
  .animationContainer {
    top: 66px;
    left: -77px;
    /* -webkit-mask-position-x: fixed; */
    /* :180px ; */
    width: 20px;
    height: 45px;
    position: absolute;
    opacity: 0.7 !important;
  }
  .animation {
    /* transform: skewY(20deg); */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -7px;
    padding: 4px;
    overflow: hidden;
    animation: scanner-loop 2s linear forwards;

    clip-path: polygon(
      0% 0%,
      0% 100%,
      /* calc(0% + 8px) 100%,
    calc(0% + 8px) calc(0% + 8px),
    calc(100% - 8px) calc(0% + 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(0% + 8px) calc(100% - 8px), */
        0% 100%,
      100% 100%,
      100% 0%
    );

    clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 87%);
    clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 86%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  }
  .light {
    filter: blur(40px);
    width: 100px;
    height: 50px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    margin: -335px 0 0 -70px;
    border: 300px solid transparent;
    border-right: none;
    /* border-left: 200px solid rgb(180, 180, 180); */
    opacity: 0.3 !important;
    position: absolute;
    animation: lightAnimation 2s linear forwards;

    transform: rotate(180deg);
  }
  @keyframes lightAnimation {
    0% {
      width: 0px;
      top: 0;
      opacity: 0;
      border-left: 0px;
    }
    10% {
      width: 5px;
    }
    20% {
      width: 10px;
    }
    30% {
      width: 20px;
    }
    40% {
      width: 25px;
    }
    50% {
      width: 30px;
    }
    60% {
      width: 35px;
    }
    70% {
      width: 40px;
      border-left: 0px;
    }
    100% {
      opacity: 0.3;
      border-left: 900px solid rgb(180, 180, 180);
    }
  }
  @keyframes scanner-loop {
    0% {
      top: 0;
      width: 10px;
    }

    30% {
      top: 0;
      width: 10px;
    }
    100% {
      top: 0;
      width: 68px;
    }
  }
}


@media (max-width: 1180px) {
  .animationContainer {
    top: 85px;
    left: -83px;
    /* -webkit-mask-position-x: fixed; */
    /* :180px ; */
    width: 20px;
    height: 45px;
    position: absolute;
    opacity: 0.7 !important;
  }

  @keyframes lightAnimation {
    0% {
      width: 0px;
      top: 0;
      opacity: 0;
      border-left: 0px;
    }
    10% {
      width: 5px;
    }
    20% {
      width: 10px;
    }
    30% {
      width: 20px;
    }
    40% {
      width: 25px;
    }
    50% {
      width: 30px;
    }
    60% {
      width: 35px;
    }
    70% {
      width: 40px;
      border-left: 0px;
    }
    100% {
      opacity: 0.3;
      border-left: 750px solid rgb(180, 180, 180);
    }
  }
  .centerText {
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 93.714px;
    font-style: normal;
    font-weight: 200;
    line-height: 100%; /* 0px */
    text-transform: uppercase;
    max-width: 750px;
  }
  .button1 {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 330px;
    height: 62px;
    border-radius: 76px;
    background-color: #c2ea2b;
  }

  .button2 {
    /* width: 100%; */
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 330px;
    height: 62px;
    border-radius: 76px;
    /* background-color: #c2ea2b; */
  }
}

@media (max-width: 1100px) {
  .animationContainer {
    display: none;
    top: 88px;
    left: -83px;
    /* -webkit-mask-position-x: fixed; */
    /* :180px ; */
    width: 20px;
    height: 45px;
    position: absolute;
    opacity: 0.7;
  }
}
@media (max-width: 780px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    position: relative;
  }

  .subContainer {
    width: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    max-width: 1150px;
    margin-top: 60px;
  }

  .textContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 0px;
    /* max-width: 1150px; */
  }

  .centerText {
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 92px;
    font-style: normal;
    font-weight: 200;
    line-height: 78px;
    text-transform: unset;
    max-width: 450px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    row-gap: 32px;
    margin-top: 48px;
    margin-bottom: 137px;
  }

  .button1 {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 100%;
    max-width: 398px;

    height: 62px;
    border-radius: 76px;
    background-color: #c2ea2b;
  }

  .button2 {
    /* width: 100%; */
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.64px;
    width: 100%;
    max-width: 398px;
    height: 62px;
    border-radius: 76px;
    /* background-color: #c2ea2b; */
  }

  .LogoContainer {
    margin-bottom: 40px;
  }

  .LogoContainer > img {
    width: 39px;
    height: 46px;
  }

  .linkContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 32px;
    margin-bottom: 33px;
    width: 100%;
  }

  .linkItem {
    color: #fff;

    font-family: TelegraphReg;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .copyright {
    margin-bottom: 38px;
    color: #fff;
    opacity: 0.5;
    font-family: TelegraphReg;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .contractAddress {
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    position: relative;
    /* background: url("../../public/images/heroBg.svg"); */

    /* background: url("../../public/images/heroBg.svg");
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-position: top center;
  background-repeat: no-repeat; */
    /* background-size:cover; */

    /* height: 900px; */
  }

  .subContainer {
    /* display: flex;
  flex-direction: column;
  align-items: center; */
    width: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    max-width: 1150px;
    margin-top: 2.326vw;
  }

  .textContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 12.791vw;

    /* max-width: 1150px; */
  }

  .centerText {
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 21.395vw;
    font-style: normal;
    font-weight: 200;
    line-height: 18.14vw;
    text-transform: unset;
  }

  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    row-gap: 7.442vw;
    margin-top: 11.163vw;
    margin-bottom: 31.86vw;
  }

  .button1 {
    /* width: 100%; */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 3.721vw;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.149vw;
    width: 100%;
    max-width: 92.558vw;

    height: 14.419vw;
    border-radius: 76px;
    background-color: #c2ea2b;
  }

  .button2 {
    /* width: 100%; */
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-top: 50px; */
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 3.721vw;
    font-style: normal;
    /* font-weight: 800; */
    line-height: normal;
    letter-spacing: 0.149vw;
    width: 100%;
    max-width: 92.558vw;
    height: 14.419vw;
    border-radius: 76px;
    /* background-color: #c2ea2b; */
  }

  .LogoContainer {
    margin-bottom: 9.302vw;
  }

  .LogoContainer > img {
    width: 39px;
    height: 46px;
  }

  .linkContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 7.442vw;
    margin-bottom: 7.674vw;
    width: 100%;
  }

  .linkItem {
    color: #fff;

    font-family: TelegraphReg;
    font-size: 3.256vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .copyright {
    margin-bottom: 8.837vw;
    color: #fff;
    opacity: 0.5;
    font-family: TelegraphReg;
    font-size: 3.256vw;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .contractAddress {
    margin-bottom: 30px;
    font-size: 12px;
  }
}

@media (min-width: 1920px) {
  .animationContainer {
    display: none;
    top: 60px;
    left: -188px;

    /* :180px ; */
    width: 50px;
    height: 60px;
    position: absolute;
    opacity: 0.7 !important;
  }
}
