.mainContainer {
  display: flex;
  justify-content: center;
  /* height: 900px; */
  width: 100%;
  /* padding-bottom: 175px; */
  padding-top: 225px;
  background: url("../../public//images/stars1.png");
  background-position: cover center;
  /* background-size: cover; */
  margin-top: 125px;
  margin-bottom: 125px;
  padding-bottom: 50px;
  padding-top: 100px;
}

.subContainer {
  position: relative;
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.leftContainer {
  width: 47%;
}
.leftContainer > img {
  width: 100%;
  height: 100%;
}

.rightContainer {
  width: 50%;
}
.text {
  color: #fff;
  font-family: TelegraphReg;
  font-size: 7.227vw;
  font-style: normal;
  font-weight: 400;
  line-height: 7.005vw; /* 96.935% */
  width: 100%;
  /* padding: 55px; */
}

.imageM {
  display: none;
}

@media (max-width: 1180px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    width: 100%;
    padding-bottom: 100px;
    padding-top: 100px;
    background: url("../../public//images/stars1.png");
    background-position: cover center;

    margin-top: 50px;
    margin-bottom:0px;
    /* background-size: cover; */
  }

  .subContainer {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
  }
  .leftContainer {
    position: relative;
    left: -10px;
    width: 100%;
    max-width: 700px;
  }
  .leftContainer > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .rightContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-family: TelegraphReg;
    font-size: 62px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 96.935% */
    width: 100%;
    max-width: 640px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 65px;

    /* padding: 55px; */
  }
  .imageM {
    display: none;
  }

  .imageD {
    display: flex;
  }
}
@media (max-width: 780px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    width: 100%;
    padding-bottom:30px;
    padding-top: 100px;
    background: url("../../public//images/stars1.png");
    background-position: cover center;
    /* background-size: cover; */
  }

  .subContainer {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    width: 100%;
    justify-content: space-between;
  }
  .leftContainer {
    position: relative;
    left: -10px;
    width: 100%;
    max-width: 600px;
  }
  .leftContainer > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .rightContainer {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .text {
    display: flex;
    justify-content: center;
    color: #fff;
    font-family: TelegraphReg;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 96.935% */
    width: 100%;
    max-width: 540px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 25px;

    /* padding: 55px; */
  }
  .imageM {
    display: none;
  }

  .imageD {
    display: flex;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    width: 100%;

    padding-bottom: 0px;
    margin-bottom: -11.628vw;
    padding-top: 23.256vw;
    background: none;
    background-position: cover center;
    /* background-size: cover; */
  }

  .subContainer {
    position: relative;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
  .leftContainer {
    position: relative;
    left: -10px;
    width: 100%;
  }
  .leftContainer > img {
    width: 100%;
    height: 97%;
    object-fit: contain;
  }
  .rightContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .text {
    color: #fff;
    font-family: TelegraphReg;
    font-size: 12.093vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 96.935% */
    width: 90%;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 5.814vw;

    /* padding: 55px; */
  }
  .imageM {
    display: flex;
  }

  .imageD {
    display: none;
  }
}
