.mainContainer {
  display: flex;
  justify-content: center;
  /* height: 900px; */
  padding-bottom: 40px;
  /* margin-top: 253px; */
  background: url("../../public/images/networkBg1.png");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 97% 824px;
  height: 900px;
  /* background-size: 95%; */

  /* background-size: 95% 821px; */
}
.badge {
  left: 0px;
  position: absolute;
  mix-blend-mode: luminosity;
}

.badgeM {
  display: none;
}
.subContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 1417px;
  position: relative;
}

.headingContainer {
  top: 20%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-items: flex-start; */
}

.mainHeading {
  /* padding-top: 200px; */
  color: #050a02;

  text-align: center;
  font-family: TelegraphReg;
  font-size: 144.84px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.84px; /* 100% */
  text-transform: uppercase;
  margin-bottom: 44px;
}

.bottomContainer {
  display: flex;
  justify-content: center;
  column-gap: 90px;
  width: 90%;
}

.Cta {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #050a02;
  font-family: TelegraphReg;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: 56px;
  text-transform: uppercase;
}

.desc {
  color: #050a02;
  font-family: TelegraphReg;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px; /* 187.5% */
  margin-bottom: 20px;
}

.button1 {
  display: flex;
  width: 100%;
  height: 62px;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  border-radius: 58px;
  background: #c2ea2b;
  border: 1px solid #c2ea2b;

  box-sizing: border-box;

  color: #050a02;
  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.64px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.button1:hover {
  transform: translateY(-5px);
  filter: brightness(90%);
  transition: all 0.4s ease-in-out;
}
.button2 {
  display: flex;
  width: 100%;
  height: 62px;
  justify-content: center;
  align-items: center;
  /* gap: 10px; */
  border-radius: 58px;

  border: 1px solid #050a02;
  box-sizing: border-box;

  color: #050a02;
  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 0.64px;
  text-decoration: none;
  transition: all 0.3s ease;
}
.button2:hover {
  transform: translateY(-5px);
  transition: all 0.4s ease-in-out;
  background-color: rgba(0, 0, 0, 0.1);
}
@media (max-width: 1800px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    padding-bottom: 40px;
    /* margin-top: 253px; */
    background: url("../../public/images/networkBg1.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 1800px;
    height: 900px;
    /* background-size: 95%; */

    /* background-size: 95% 821px; */
  }
}

@media (max-width: 1480px) {
  .badge {
    left: 0px;
    position: absolute;
    mix-blend-mode: luminosity;
    width: 250px;
  }

  .badgeM {
    display: none;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1417px;
    position: relative;
  }

  .headingContainer {
    top: 22%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1250px;
    /* align-items: flex-start; */
  }

  .mainHeading {
    /* padding-top: 200px; */
    color: #050a02;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 114.84px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.84px; /* 100% */
    text-transform: uppercase;
    margin-bottom: 44px;
  }

  .bottomContainer {
    display: flex;
    justify-content: center;
    column-gap: 40px;
    width: 90%;
  }

  .Cta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 200% */
    text-transform: uppercase;
  }

  .desc {
    text-align: center;
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    margin-bottom: 20px;
    width: 90%;
  }

  .button1 {
    display: flex;
    width: 100%;
    height: 62px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;
    background: #c2ea2b;
    border: 1px solid #c2ea2b;

    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }

  .button2 {
    display: flex;
    width: 100%;
    height: 62px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;

    border: 1px solid #050a02;
    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }
}

@media (max-width: 1024px) {
  .badge {
    top: 20px;
    left: 0px;
    position: absolute;
    mix-blend-mode: luminosity;
    width: 200px;
  }

  .badgeM {
    display: none;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1417px;
    position: relative;
  }

  .headingContainer {
    top: 22%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1250px;
    /* align-items: flex-start; */
  }

  .mainHeading {
    /* padding-top: 200px; */
    color: #050a02;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 94.84px;
    font-style: normal;
    font-weight: 400;
    line-height: 94.84px; /* 100% */
    text-transform: uppercase;
    margin-bottom: 44px;
  }

  .bottomContainer {
    display: flex;
    justify-content: center;
    column-gap: 30px;
    width: 90%;
  }

  .Cta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 200% */
    text-transform: uppercase;
  }

  .desc {
    text-align: center;
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    margin-bottom: 20px;
    width: 90%;
  }

  .button1 {
    display: flex;
    max-width: 340px;
    width: 100%;
    height: 62px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;
    background: #c2ea2b;
    border: 1px solid #c2ea2b;

    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }

  .button2 {
    display: flex;
    max-width: 340px;

    width: 100%;
    height: 62px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;

    border: 1px solid #050a02;
    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }
}

@media (max-width: 780px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    padding-bottom: 40px;
    /* margin-top: 253px; */
    background: url("../../public/images/networkBGM.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% 824px;
    /* height: 824px; */
    /* background-size: 95%; */

    /* background-size: 95% 821px; */
  }
  .badge {
    display: none;
  }

  .badgeM {
    display: flex;
    left: 0px;
    top: 80px;
    position: absolute;
    mix-blend-mode: luminosity;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1417px;
    position: relative;
  }

  .headingContainer {
    top: 21%;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* align-items: flex-start; */
  }

  .mainHeading {
    /* padding-top: 200px; */
    color: #050a02;
    max-width: 450px;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 52px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100% */
    text-transform: uppercase;
    margin-bottom: 36px;
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 36px;
    width: 90%;
  }

  .Cta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 56px; /* 200% */
    text-transform: uppercase;
  }

  .desc {
    color: #050a02;
    font-family: TelegraphReg;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 187.5% */
    margin-bottom: 16px;
  }

  .button1 {
    display: flex;
    width: 100%;
    height: 62px;
    max-width: 340px;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;
    background: #c2ea2b;
    border: 1px solid #c2ea2b;

    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }

  .button2 {
    display: flex;
    width: 100%;
    height: 62px;
    max-width: 340px;

    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;

    border: 1px solid #050a02;
    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    height: 209.302vw;
    padding-bottom: 12.902vw;
    /* margin-top: 253px; */
    background: url("../../public/images/networkBGM.svg");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 100% auto;
    /* height: 824px; */
    /* background-size: 95%; */

    /* background-size: 95% 821px; */
  }
  .badge {
    display: none;
  }

  .badgeM {
    display: flex;
    left: 0px;
    top: 18.605vw;
    width: 19.535vw;
    height: 33.721vw;
    position: absolute;
    mix-blend-mode: luminosity;
  }
  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 1417px;
    position: relative;
  }

  .headingContainer {
    top: 44.186vw;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* align-items: flex-start; */
  }

  .mainHeading {
    /* padding-top: 200px; */
    color: #050a02;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 12.093vw;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 100% */
    text-transform: uppercase;
    margin-bottom: 8.372vw;
  }

  .bottomContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 8.372vw;
    width: 90%;
  }

  .Cta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    color: #050a02;
    font-family: TelegraphReg;
    font-size: 7.442vw;
    font-style: normal;
    font-weight: 400;
    line-height: 14.884vw; /* 200% */
    text-transform: uppercase;
  }

  .desc {
    color: #050a02;
    font-family: TelegraphReg;
    text-align: center;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 400;
    line-height: 6.977vw; /* 187.5% */
    margin-bottom: 3.721vw;
  }

  .button1 {
    display: flex;
    width: 100%;
    height: 14.419vw;
    max-width: 79.07vw;
    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;
    background: #c2ea2b;
    border: 1px solid #c2ea2b;

    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.149vw;
  }

  .button2 {
    display: flex;
    width: 100%;
    height: 14.419vw;
    max-width: 79.07vw;

    justify-content: center;
    align-items: center;
    /* gap: 10px; */
    border-radius: 58px;

    border: 1px solid #050a02;
    box-sizing: border-box;

    color: #050a02;
    font-family: TelegraphBold;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.149vw;
  }
}

@media (min-width: 1920px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    /* height: 900px; */
    /* margin-top: 253px; */
    /* background: url("../../public/images/networkBG.png"); */

    background: url("../../public/images/networkBg1.png");

    background-repeat: no-repeat;
    background-position: top left;
    background-size: 97% 831px;
  }
}
