.mainContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.subContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.textContainer {
  margin-top: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 30px;
}

.mainTextCont {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.mainText {
  color: #fff;

  text-align: center;
  font-family: TelegraphLight;
  font-size: 72px;
  font-style: normal;
  font-weight: 200;
  line-height: 60%;
  text-transform: uppercase;
  /* padding-bottom: 10px; */
  /* width: 5px; */
}
.stop {
  width: 24px;
  height: 6px;

  border-radius: 1px;
  background: #c2ea2b;
}

.subText {
  width: 100%;
  max-width: 855px;
  /* margin-top: 10px; */
  color: #fff;

  text-align: center;
  font-family: TelegraphReg;
  font-size: 24px;
  font-style: normal;
  font-weight: 100;
  line-height: 38px;
  text-transform: capitalize;
}

.bottomContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 70px;
  /* height: 1500px; */
  width: 100%;
  /* background: url("../../public/images/oracleBG1.svg"); */
  background-repeat: no-repeat;

  background-position: top center;
}

.imageContainer {
  position: relative;
  margin-top: -100px;
  /* width: 1100px; */
  /* position: cover top ; */
}
.imageContainer > img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.imageItem {
  background-color: white;
  position: absolute;
  color: #050a02;
  border-radius: 161.188px;
  border: 0.806px solid rgba(255, 255, 255, 0.8);
  padding: 0.25vw 0.781vw 0.08vw 0.781vw;
  background: #fff;
  font-family: TelegraphReg;
  font-size: 1.888vw;
  font-style: normal;
  font-weight: 400;
  line-height: 116.4%; /* 16.886px */
  text-transform: capitalize;
}
@keyframes load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.imageItem1,
.imageItem2,
.imageItem3,
.imageItem4,
.imageItem5,
.imageItem6,
.imageItem7,
.imageItem8,
.imageItem9,
.imageItem10,
.imageItem11,
.imageItem12 {
  animation-name: load;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes load {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.imageItem1 {
  left: 30.9vw;
  top: 18.5%;
  animation-duration: 0.2s;
}

.imageItem2 {
  left: 25.5vw;
  top: 27.2%;
  animation-duration: 0.4s;
}
.imageItem3 {
  left: 24.2vw;
  top: 36.2%;
  animation-duration: 0.8s;
}

.imageItem4 {
  left: 24.2vw;
  top: 45.2%;
  animation-duration: 1.2s;
}

.imageItem5 {
  left: 25.8vw;
  top: 54.2%;
  animation-duration: 1.6s;
}

.imageItem6 {
  left: 30vw;
  top: 63.2%;
  animation-duration: 2s;
}

.imageItem7 {
  right: 32.3vw;
  top: 18.8%;
  animation-duration: 0.2s;
}

.imageItem8 {
  right: 25.5vw;
  top: 28%;
  animation-duration: 0.4s;
}

.imageItem9 {
  right: 27vw;
  top: 36.8%;
  animation-duration: 0.8s;
}

.imageItem10 {
  right: 27.1vw;
  top: 45.8%;
  animation-duration: 1.2s;
}

.imageItem11 {
  right: 28.7vw;
  top: 55%;
  animation-duration: 1.6s;
}

.imageItem12 {
  right: 30.8vw;
  top: 64.2%;
  animation-duration: 2s;
}

@media (max-width: 1180px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .textContainer {
    margin-top: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
    margin-bottom: 10px;
  }

  .mainTextCont {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .mainText {
    color: #fff;

    text-align: center;
    font-family: TelegraphLight;
    font-size: 52px;
    font-style: normal;
    font-weight: 200;
    line-height: 60%;
    text-transform: uppercase;
    /* padding-bottom: 10px; */
    /* width: 5px; */
  }
  .stop {
    width: 20px;
    height: 5px;

    border-radius: 1px;
    background: #c2ea2b;
  }

  .subText {
    width: 95%;
    max-width: 855px;
    /* margin-top: 10px; */
    color: #fff;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: 32px;
    text-transform: capitalize;
  }

  .bottomContainer {
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    padding-top: 90px;
    padding-bottom: 35px;
    /* height: 1500px; */
    width: 100%;
    /* background: url("../../public/images/oracleBG1.svg"); */
    background-repeat: no-repeat;

    background-position: top center;
  }

  .imageContainer {
    margin-top: -100px;
    width: 100%;
    /* height: 430px; */

    /* width: 1100px; */
    /* position: cover top ; */
  }
  .imageContainer > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@media (max-width: 780px) {
  .mainContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .textContainer {
    margin-top: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 30px;
    margin-bottom: 10px;
  }

  .mainTextCont {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .mainText {
    color: #fff;

    text-align: center;
    font-family: TelegraphLight;
    font-size: 52px;
    font-style: normal;
    font-weight: 200;
    line-height: 60%;
    text-transform: uppercase;
    /* padding-bottom: 10px; */
    /* width: 5px; */
  }
  .stop {
    width: 20px;
    height: 5px;

    border-radius: 1px;
    background: #c2ea2b;
  }

  .subText {
    width: 95%;
    max-width: 855px;
    /* margin-top: 10px; */
    color: #fff;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 18px;
    font-style: normal;
    font-weight: 100;
    line-height: 32px;
    text-transform: capitalize;
  }

  .bottomContainer {
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    padding-top: 90px;
    padding-bottom: 35px;
    /* height: 1500px; */
    width: 100%;
    /* background: url("../../public/images/oracleBG1.svg"); */
    background-repeat: no-repeat;

    background-position: top center;
  }

  .imageContainer {
    margin-top: -100px;
    width: 1100px;
    height: 430px;

    /* width: 1100px; */
    /* position: cover top ; */
  }
  .imageContainer > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .imageItem {
    /* left: 30vw;
  top: 50%; */
    background-color: white;
    position: absolute;
    color: #050a02;
    border-radius: 161.188px;
    border: 0.806px solid rgba(255, 255, 255, 0.8);
    padding: 1.65px 6.5px 0px 6.5px;
    background: #fff;
    font-family: TelegraphReg;
    font-size: 14.767px;
    font-style: normal;
    font-weight: 400;
    line-height: 116.4%; /* 16.886px */
    text-transform: capitalize;
  }

  .imageItem1 {
    left: 35%;
    top: 18.5%;
  }

  .imageItem2 {
    left: 31%;
    top: 27.2%;
  }
  .imageItem3 {
    left: 30%;
    top: 36.2%;
  }

  .imageItem4 {
    left: 30.1%;
    top: 45.2%;
  }

  .imageItem5 {
    left: 31%;
    top: 54.2%;
  }

  .imageItem6 {
    left: 34%;
    top: 63.2%;
  }

  .imageItem7 {
    right: 36%;
    top: 18.8%;
  }

  .imageItem8 {
    right: 31%;
    top: 28%;
  }

  .imageItem9 {
    right: 32%;
    top: 36.8%;
  }

  .imageItem10 {
    right: 32.1%;
    top: 45.8%;
  }

  .imageItem11 {
    right: 33.5%;
    top: 55%;
  }

  .imageItem12 {
    right: 35.2%;
    top: 64.2%;
  }
}

@media (max-width: 480px) {
  /* .bottomContainer {
    overflow: hidden;
  }

  .imageContainer {
    width: 1100px;
    position: cover top ;
  } */

  .mainContainer {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .textContainer {
    margin-top: 69.767vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 6.977vw;
    margin-bottom: 2.326vw;
  }

  .mainTextCont {
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }

  .mainText {
    color: #fff;

    text-align: center;
    font-family: TelegraphLight;
    font-size: 12.093vw;
    font-style: normal;
    font-weight: 200;
    line-height: 60%;
    text-transform: uppercase;
    /* padding-bottom: 10px; */
    /* width: 5px; */
  }
  .stop {
    width: 4.651vw;
    height: 1.163vw;

    border-radius: 1px;
    background: #c2ea2b;
  }

  .subText {
    width: 95%;
    max-width: 198.837vw;
    /* margin-top: 10px; */
    color: #fff;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 4.186vw;
    font-style: normal;
    font-weight: 100;
    line-height: 7.442vw;
    text-transform: capitalize;
  }

  .bottomContainer {
    overflow: hidden;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    padding-top: 20.93vw;
    padding-bottom: 8.14vw;
    /* height: 1500px; */
    width: 100%;
    /* background: url("../../public/images/oracleBG1.svg"); */
    background-repeat: no-repeat;

    background-position: top center;
  }

  .imageContainer {
    margin-top: -100px;
    width: 255.814vw;
    height: 86.047vw;

    /* width: 1100px; */
    /* position: cover top ; */
  }
  .imageContainer > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .imageItem {
    /* left: 30vw;
  top: 50%; */
    background-color: white;
    position: absolute;
    color: #050a02;
    border-radius: 161.188px;
    border: 0.806px solid rgba(255, 255, 255, 0.8);
    padding: 1.65px 6.5px 0px 6.5px;
    background: #fff;
    font-family: TelegraphReg;
    font-size: 3.534vw;
    font-style: normal;
    font-weight: 400;
    line-height: 116.4%; /* 16.886px */
    text-transform: capitalize;
  }

  .imageItem1 {
    left: 36.3%;
    top: 18.5%;
  }

  .imageItem2 {
    left: 32.6%;
    top: 27.2%;
  }
  .imageItem3 {
    left: 31.9%;
    top: 36.2%;
  }

  .imageItem4 {
    left: 31.9%;
    top: 45.2%;
  }

  .imageItem5 {
    left: 32.7%;
    top: 54.2%;
  }

  .imageItem6 {
    left: 36%;
    top: 63.2%;
  }

  .imageItem7 {
    right: 37.6%;
    top: 18.8%;
  }

  .imageItem8 {
    right: 32.5%;
    top: 28%;
  }

  .imageItem9 {
    right: 33.7%;
    top: 36.8%;
  }

  .imageItem10 {
    right: 33.9%;
    top: 45.8%;
  }

  .imageItem11 {
    right: 35%;
    top: 55%;
  }
  

  .imageItem12 {
    right: 36.2%;
    top: 64.2%;
  }
}

@media (min-width: 1920px) {
  .imageContainer {
    margin-top: -100px;
    width: 100%;
    /* width: 1100px; */
    /* position: cover top ; */
  }
}
