.slideTextContainer {
  position: absolute;
  overflow: hidden;
  top: 0;
  /* display: flex; */

  width: 100%;
}

.scroll {
  display: flex;
}

.fixedText {
  position: absolute;
  right: 0px;
  top: 0px;
  text-decoration: none;
  font-family: TelegraphBold;
    /* font-family: TelegraphReg; */

font-weight: 100;
  z-index: 10;
  background-color: rgb(206, 196, 196);
  background-color: lightgrey;
  font-size: 15px;
  padding: 0px 20px;
  cursor: pointer;
  border-radius: 5px ;
}

.slideText {
  /* position: absolute; */
  font-size: 15px;
  font-family: TelegraphReg;
  /* color: red; */
  width: max-content;

 
  background-color: black;

  /* background-clip: text; */
  /* color: transparent; */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: scrollText 700s linear infinite;
  -webkit-animation: scrollText 700s linear infinite;
  animation: scrollText 700s infinite linear;
}

@keyframes scrollText {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
  /* from {transform: translateX(0) }
  to {transform: translateX(calc(0px - 50%));} */
}

.runningLine {
  color: white;
  /* color:black */
  /* color: rgb(255, 192, 192); */
}
