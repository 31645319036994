.mainContainer {
  display: flex;
  justify-content: center;
  /* background: url("../../public/images/heroBg.svg");
  height: 100%;
  width: 100%;
  min-height: 100vh;
  background-position: top center;
  background-repeat: no-repeat; */
  /* background-size:cover; */
}

.subContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.animationContainer {
  display: unset;

  top: 91px;
  left: -404px;

  /* :180px ; */
  width: 50px;
  height: 60px;
  position: absolute;
  opacity: 0.7;
}
.light {
  filter: blur(40px);
  width: 100px;
  height: 50px;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  margin: -285px 0 0 -70px;
  border: 300px solid transparent;
  border-right: none;
  /* border-left: 200px solid rgb(180, 180, 180); */
  opacity: 0.3;
  position: absolute;
  animation: lightAnimation 2s linear forwards;

  transform: rotate(185deg);
}

@keyframes lightAnimation {
  0% {
    width: 0px;
    top: 0;
    opacity: 0;
    border-left: 0px;
  }
  10% {
    width: 5px;
  }
  20% {
    width: 10px;
  }
  30% {
    width: 20px;
  }
  40% {
    width: 25px;
  }
  50% {
    width: 30px;
  }
  60% {
    width: 35px;
  }
  70% {
    width: 40px;
    border-left: 0px;
  }
  100% {
    opacity: 0.3;
    border-left: 1100px solid rgb(180, 180, 180);
  }
}

@keyframes lightAnimation2 {
  0% {
    width: 0px;
    top: 0;
    opacity: 0;
    border-left: 0px;
  }
  10% {
    width: 5px;
  }
  20% {
    width: 10px;
  }
  30% {
    width: 20px;
  }
  40% {
    width: 25px;
  }
  50% {
    width: 30px;
  }
  60% {
    width: 35px;
  }
  70% {
    width: 40px;
    border-left: 0px;
  }
  100% {
    opacity: 0.3;
    border-left: 62vw solid rgb(180, 180, 180);
  }
}

@keyframes scanner-loop {
  0% {
    top: 0;
    width: 20px;
  }

  30% {
    top: 0;
    width: 20px;
  }
  100% {
    top: 0;
    width: 60px;
  }
}

.animation {
  /* transform: skewY(20deg); */
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: -8px;
  overflow: hidden;
  animation: scanner-loop 2s linear forwards;

  clip-path: polygon(
    0% 0%,
    0% 100%,
    /* calc(0% + 8px) 100%,
    calc(0% + 8px) calc(0% + 8px),
    calc(100% - 8px) calc(0% + 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(0% + 8px) calc(100% - 8px), */
      0% 100%,
    100% 100%,
    100% 0%
  );

  clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 87%);
  clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 86%);
}

.animation:before {
  content: "";
  display: block;
  background: linear-gradient(
    to right,
    hsl(75, 85%, 40%),
    hsl(75, 85%, 70%),
    #c2ea2b,
    hsl(75, 85%, 40%),
    hsl(75, 85%, 70%),
    #c2ea2b,
    hsl(75, 85%, 40%)
  );
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 400%;
  animation: dynamic-border-animation 2s linear infinite;
  transform: translateX(-50%);
}

@keyframes dynamic-border-animation {
  to {
    transform: translateX(0%);
  }
}

/* 
.LogoContainer {
  margin-top: 30px;
  -webkit-transition: -webkit-transform 2s ease-in-out;
  -ms-transition: -ms-transform 2s ease-in-out;
  transition: transform 2s ease-in-out;
    animation-iteration-count: infinite;

}

.LogoContainer:hover {
  transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
} */
.LogoContainer {
  /* margin-top: 30px;
  transition: transform 2s ease-in-out;
  animation-name: zoomInOut;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-name: zoomInOut;
  animation-timing-function: cubic-bezier(1, 0.01, 0, 1.04);
  margin-top: 30px;
  transition: transform 2s cubic-bezier(0.96, 0.02, 0.12, 1);
}

@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(0.8); /* Start and end at normal size */
  }
  50% {
    transform: scale(1); /* Midway through the animation, zoom in */
  }
}

.LogoContainer:hover {
  animation-play-state: paused; /* Pause animation on hover */
}

.textContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  margin-top: 113.8px;
  max-width: 747px;
  position: relative;
}

.topText {
  color: #fff;

  text-align: center;
  font-family: TelegraphReg;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.952px;
  letter-spacing: 4.32px;
  text-transform: uppercase;
  width: 100%;
  max-width: 370px;
}

.centerText {
  margin-top: 19px;
  margin-bottom: 47px;
  color: #fff;
  text-align: center;
  font-family: TelegraphLight;
  font-size: 132px;
  font-style: normal;
  font-weight: 200;
  line-height: 100px; /* 75.758% */
  letter-spacing: -2px;
}

.subText {
  color: #fff;
  text-align: center;
  font-family: TelegraphReg;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px; /* 158.333% */
  text-transform: capitalize;
  width: 90%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  color: #050a02;
  font-family: TelegraphBold;
  font-size: 16px;
  font-style: normal;
  /* font-weight: 800; */
  line-height: normal;
  letter-spacing: 0.64px;
  width: 266px;
  height: 62px;
  border-radius: 76px;
  background-color: #c2ea2b;
  text-decoration: none;
  transition: all 0.3s ease;
}

.button:hover {
  transform: translateY(-5px);
  /* background-color: #a0d81e;
  color: #030801; */
  filter: brightness(90%);
  transition: all 0.4s ease-in-out;
}
/* 
.bottomContainer {
  margin-top: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 30px;
}

.bmainTextCont {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

.bmainText {
  color: #fff;

  text-align: center;
  font-family: TelegraphLight;
  font-size: 72px;
  font-style: normal;
  font-weight: 200;
  line-height: 60%;
  text-transform: uppercase;

}
.stop {
  width: 24px;
  height: 6px;

  border-radius: 1px;
  background: #c2ea2b;
}

.bsubText {
  width: 100%;
  max-width: 855px;

  color: #fff;

  text-align: center;
  font-family: TelegraphReg;
  font-size: 24px;
  font-style: normal;
  font-weight: 100;
  line-height: 38px;
  text-transform: capitalize;
} */

@media (max-width: 1600px) {
  .animationContainer {
    top: 107px;
    left: -355px;
    /* -webkit-mask-position-x: fixed; */
    /* :180px ; */
    width: 20px;
    height: 55px;
    position: absolute;
    opacity: 0.7;
  }
  .animation {
    /* transform: skewY(20deg); */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -8px;
    overflow: hidden;
    animation: scanner-loop 2s linear forwards;

    clip-path: polygon(
      0% 0%,
      0% 100%,
      /* calc(0% + 8px) 100%,
    calc(0% + 8px) calc(0% + 8px),
    calc(100% - 8px) calc(0% + 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(0% + 8px) calc(100% - 8px), */
        0% 100%,
      100% 100%,
      100% 0%
    );

    clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 87%);
    clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 86%);
    clip-path: polygon(19% 11%, 100% 0, 100% 100%, 19% 86%);
  }
}

@media (max-width: 1500px) {
  .animationContainer {
    top: 145px;
    left: -168px;
    /* -webkit-mask-position-x: fixed; */
    /* :180px ; */
    width: 20px;
    height: 45px;
    position: absolute;
    opacity: 0.7;
  }
  .animation {
    /* transform: skewY(20deg); */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: -7px;
    padding: 6px;
    overflow: hidden;
    animation: scanner-loop 2s linear forwards;

    clip-path: polygon(
      0% 0%,
      0% 100%,
      /* calc(0% + 8px) 100%,
    calc(0% + 8px) calc(0% + 8px),
    calc(100% - 8px) calc(0% + 8px),
    calc(100% - 8px) calc(100% - 8px),
    calc(0% + 8px) calc(100% - 8px), */
        0% 100%,
      100% 100%,
      100% 0%
    );

    clip-path: polygon(0 17%, 100% 0, 100% 100%, 0 87%);
    clip-path: polygon(0 11%, 100% 0, 100% 100%, 0 86%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 0);
  }
  .light {
    filter: blur(40px);
    width: 100px;
    height: 50px;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    margin: -335px 0 0 -70px;
    border: 300px solid transparent;
    border-right: none;
    /* border-left: 200px solid rgb(180, 180, 180); */
    opacity: 0.3;
    position: absolute;
    animation: lightAnimation 2s linear forwards;

    transform: rotate(180deg);
  }
  @keyframes lightAnimation {
    0% {
      width: 0px;
      top: 0;
      opacity: 0;
      border-left: 0px;
    }
    10% {
      width: 5px;
    }
    20% {
      width: 10px;
    }
    30% {
      width: 20px;
    }
    40% {
      width: 25px;
    }
    50% {
      width: 30px;
    }
    60% {
      width: 35px;
    }
    70% {
      width: 40px;
      border-left: 0px;
    }
    100% {
      opacity: 0.3;
      border-left: 850px solid rgb(180, 180, 180);
    }
  }
  @keyframes scanner-loop {
    0% {
      top: 0;
      width: 10px;
    }

    30% {
      top: 0;
      width: 10px;
    }
    100% {
      top: 0;
      width: 68px;
    }
  }
}

@media (max-width: 1180px) {
  .topText {
    color: #fff;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20.952px;
    letter-spacing: 4.32px;
    text-transform: uppercase;
    width: 100%;
    max-width: 370px;
  }

  .centerText {
    margin-top: 19px;
    margin-bottom: 47px;
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    font-size: 102px;
    font-style: normal;
    font-weight: 200;
    line-height: 76%; /* 75.758% */
    letter-spacing: -2px;
  }

  .subText {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 38px; /* 158.333% */
    text-transform: capitalize;
    width: 93%;
    max-width: 600px;
  }
}

@media (max-width: 1100px) {
  .animationContainer {
    display: none;
  }
}
@media (max-width: 780px) {
  .mainContainer {
    display: flex;
    justify-content: center;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .LogoContainer {
    margin-top: 30px;
  }

  .LogoContainer > img {
    width: 33.3px;
    height: 39.539px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 172px;
    max-width: 747px;
    width: 95%;
    max-width: 405px;
  }

  .topText {
    color: #fff;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 114.4%; /* 18.304px */
    letter-spacing: 3.84px;
    text-transform: uppercase;
    /* width: 90%; */
    max-width: 320px;
  }

  .centerText {
    margin-top: 28px;
    margin-bottom: 33px;
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    color: #fff;

    text-align: center;
    font-size: 72px;
    font-weight: 200;
    line-height: 58px; /* 80.556% */
    letter-spacing: -2px;
  }

  .subText {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* width: 90%; */
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 33px;
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 16px;

    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.64px;

    width: 100%;
    height: 62px;
    max-width: 398px;
  }
}

@media (max-width: 480px) {
  .mainContainer {
    display: flex;
    justify-content: center;
  }

  .subContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .LogoContainer {
    margin-top: 30px;
  }

  .LogoContainer > img {
    width: 33.3px;
    height: 39.539px;
  }

  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    margin-top: 40vw;
    max-width: 173.721vw;
    width: 95%;
    max-width: 94.186vw;
  }

  .topText {
    color: #fff;

    text-align: center;
    font-family: TelegraphReg;
    font-size: 3.721vw;
    font-style: normal;
    font-weight: 400;
    line-height: 114.4%; /* 18.304px */
    letter-spacing: 0.893vw;
    text-transform: uppercase;
    /* width: 90%; */
    max-width: 75.419vw;
  }

  .centerText {
    margin-top: 6.512vw;
    margin-bottom: 7.674vw;
    color: #fff;
    text-align: center;
    font-family: TelegraphLight;
    color: #fff;

    text-align: center;
    font-size: 16.744vw;
    font-weight: 200;
    line-height: 13.488vw; /* 80.556% */
    letter-spacing: -0.465vw;
  }

  .subText {
    color: #fff;
    text-align: center;
    font-family: TelegraphReg;
    font-size: 4.186vw;
    font-style: normal;
    font-weight: 400;
    line-height: 7.442vw;
    /* width: 90%; */
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 7.674vw;
    color: #050a02;

    font-family: TelegraphBold;
    font-size: 3.721vw;

    font-weight: 800;
    line-height: normal;
    letter-spacing: 0.149vw;

    width: 100%;
    height: 14.419vw;
    max-width: 398px;
  }
}

@media (min-width: 1920px) {
  .animationContainer {
    display: none;
  }
}
